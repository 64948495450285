.m-1{margin:.25rem .25rem!important}
.mt-1{margin-top:.25rem!important}
.mr-1{margin-right:.25rem!important}
.mb-1{margin-bottom:.25rem!important}
.ml-1{margin-left:.25rem!important}
.mx-1{margin-right:.25rem!important;margin-left:.25rem!important}
.my-1{margin-top:.25rem!important;margin-bottom:.25rem!important}
.m-2{margin:.5rem .5rem!important}
.mt-2{margin-top:.5rem!important}
.mr-2{margin-right:.5rem!important}
.mb-2{margin-bottom:.5rem!important}
.ml-2{margin-left:.5rem!important}
.mx-2{margin-right:.5rem!important;margin-left:.5rem!important}
.my-2{margin-top:.5rem!important;margin-bottom:.5rem!important}
.m-3{margin:1rem 1rem!important}
.mt-3{margin-top:1rem!important}
.mr-3{margin-right:1rem!important}
.mb-3{margin-bottom:1rem!important}
.ml-3{margin-left:1rem!important}
.mx-3{margin-right:1rem!important;margin-left:1rem!important}
.my-3{margin-top:1rem!important;margin-bottom:1rem!important}
.m-4{margin:1.5rem 1.5rem!important}
.mt-4{margin-top:1.5rem!important}
.mr-4{margin-right:1.5rem!important}
.mb-4{margin-bottom:1.5rem!important}
.ml-4{margin-left:1.5rem!important}
.mx-4{margin-right:1.5rem!important;margin-left:1.5rem!important}
.my-4{margin-top:1.5rem!important;margin-bottom:1.5rem!important}
.m-5{margin:3rem 3rem!important}
.mt-5{margin-top:3rem!important}
.mr-5{margin-right:3rem!important}
.mb-5{margin-bottom:3rem!important}
.ml-5{margin-left:3rem!important}
.mx-5{margin-right:3rem!important;margin-left:3rem!important}
.my-5{margin-top:3rem!important;margin-bottom:3rem!important}