@import "../variables";

.standalone_page  {
  display: flex;
  flex-direction: column;

  .mobile { 
    display: none;
  }
  .desktop {
    display: block;
  }

  &__header {
    margin-top: -67px;
    min-height: 416px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background: var(--background-url);
    position: relative;
    background-size: cover;
    background-position: bottom;
    .img-header {
      position: absolute;
      top: 0;
      padding: 30px 0;
      width: 100%;
      text-align: center;
    
      @media (max-width: 992px) {
        padding: 15px 0;
      }
    }
  }
  
  &__body {
    z-index: 100;
    margin-top: -296px;
  
    @media (max-width: 576px) {
      margin-top: -330px;
    }
  
    @media (max-width: 992px) and (min-width: 577px) {
      margin-top: -328px;
    }
    &__content {
      display: flex;
      flex-direction: row;
      flex: 1;
    
      background-image: url('https://fma.dev.techland.link/trade/assets/images/background/reward-background.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 15px;
      max-width: 913px;
      height: 459px;
      margin: auto;
    
      @media (max-width: 992px) {
        height: 330px;
      }
    
      @media (max-width: 576px) {
        background: none;
        background-color: white;
    
        flex-direction: column;
        height: 625px;
    
        margin-bottom: 60px;
      }
    }
  }
  .app__background {
    background-image: url('https://fma.dev.techland.link/trade/assets/images/background/fv-bg.png');
    background-repeat: no-repeat;
    background-size: 61%;
    flex: 0.5;
    background-position: 55% 51%;
  
    @media (max-width: 992px) {
      background-size: contain;
  
    }
  
    @media (max-width: 576px) {
      // display: none;
      flex: 1;
      // background-position: unset;
      // background-size: cover;
      height: 310px;
      margin: 15px 0;
    }
  
  
  }
  
  .app__introduce {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 52px;
  
    @media (max-width: 992px) {
      flex: 0.5;
  
    }
  
    @media (max-width: 576px) {
      flex: 1;
      text-align: center;
      align-items: center;
      padding-top: 0;
    }
  
  
  
    img {
      max-width: 150px;
      margin: 0 auto;
    }
  
    p {
      font-size: 14px;
      margin: 30px 0 0;
      // max-width: 332px;
  
      @media (max-width: 576px) {
        padding: 15px 0;
        margin: 0;
        max-width: 269px;
      }
  
    }
  
    a {
      text-decoration: underline;
    }
  
    .btn--blue {
      width: 332px;
  
      @media (max-width: 576px) {
        width: 250px;
      }
    }
  
    span {
      font-size: 14px;
      // margin-top: 10px;
  
      &.no-wrap{
        white-space: nowrap;
      }
  
      @media (max-width: 576px) {
        margin-top: 0;
        max-width: 226px;
  
      }
    }
  
    .group_qr {
      display: flex;
      align-items: center;
      margin-top: 12px;
  
      &-img {
        padding: 7px;
        background-color: #F5F7F9;
        border-radius: 8px;
  
        width: 116px;
      height: 116px;
      display: flex;
      align-items: center;
      justify-content: center;
  
        img {
          width: 104px;
          height: 104px;
          background: white;
          padding: 5px;
          border-radius: 8px;
        }
      }
  
      &-title {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
  
        label {
          font-size: 14px;
          line-height: 28px;
          color: #263248;
          margin-bottom: 0;
        }
  
        span {
          font-family: OpenSans7;
          font-size: 20px;
          line-height: 28px;
          color: #263248;
        }
      }
    }
  
  }
  
  .btn-app {
    img {
      max-height: 40px;
      margin-right: 20px;
    }
  
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: space-between;
      width: 250px;
  
      img {
        margin-right: 0px;
      }
  
    }
  }
  
  
  &__footer {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  
    @media screen and (max-width: 992px) {
      bottom: calc(100vh - 550px);
    }
  
    @media screen and (max-width: 576px) {
      bottom: calc(100vh - 857px);
  
    }
  
  
  }
  
  @media screen and (max-width: 767px) {
    .mobile {
      display: block !important;
    }
    .desktop {
      display: none !important;
    }
    .card-block {
      .robo-left {
        display: none
      }
    }
  
  }
}
