/* Variable */
:root {
  // dynamic config
  --white: #fff;
  --primary-color: #0368FF;
  --background-url: url(https://files.fmarket.vn/blog/assets/params/images/background/bg-head-page.webp) no-repeat top;
  --font-color: #263248;
  --blue-btn-link: #2D9CDB;
  --txt-blue: #0F3256;
  --black: #263248;
  --table-bg: #F5F9FF;
  --light-gray-border: #E3E9F6;
  --table-border: #E3E9F6;
  --red: #da251c;
  --orange: #ffab00;
  --blue-link: #2F80ED;
}

$white : var(--white);
$base-blue: var(--primary-color);
$black : var(--font-color);
$blue-btn-link: var(--blue-btn-link);
$blue : var(--primary-color);
$dark-blue : var(--primary-color);
$txt-blue : var(--txt-blue);
$table-bg: var(--table-bg);
$tab-text: var(--tab-text);
$blue-link: var(--blue-link);
$light-gray-border: var(--light-gray-border);
/* Font */
$root : 18;
