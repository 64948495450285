.article-content {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #555;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0.5em;
    text-rendering: optimizeSpeed;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .heading-font,
  .banner h1,
  .banner h2 {
    font-weight: 700;
  }

  text-align: justify;

  .wp-caption {
    margin-bottom: 2em;
    max-width: 100%;
  }

  @media (min-width: 850px) {
    .aligncenter {
      clear: both;
      display: block;
      margin: 0 auto;
    }
  }

  .aligncenter {
    clear: both;
  }

  .wp-caption .wp-caption-text {
    padding: 0.4em;
    font-size: .9em;
    background: rgba(0, 0, 0, .05);
    font-style: italic;
  }

  .wp-caption-text {
    text-align: center;
  }

  figure {
    img {
      -webkit-transition: opacity 1s;
      -o-transition: opacity 1s;
      transition: opacity 1s;
      opacity: 1;
    }

    img {
      max-width: 100%;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }

    img {
      border-style: none;
    }
  }

  img {
    object-fit: contain !important;
    height: auto !important;
    ;
  }

  a {
    color: #0368ff !important;
    text-decoration: underline !important;
  }

  .card {
    border: none !important;
  }

  table {

    th,
    td {
      padding: 0.5em;
      text-align: left;
      border-bottom: 1px solid #ececec;
      line-height: 1.3;
      font-size: .9em;
    }
  }

}
