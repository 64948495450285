@media screen and (max-width: 992px) and (min-width: 576px) {
  .isMobile {
    display: block !important;
  }

  .isDesktop {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .isMobile {
    display: none !important;
  }

  .isDesktop {
    display: block !important;
  }

  .table-products {
    .dropdown-display-value {
      height: 43px;
      border-color: #C7D0E8 !important;
      border-radius: 3px;
    }

    .dropdown-content {
      border-color: #C7D0E8 !important;
      border-radius: 3px;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1300px;
  }
}

// -------------------max-width--------------------------
@media screen and (max-width: 1199px) {
  .container {
    max-width: 1130px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    width: 911px;
    max-width: 100%;
  }

  .table-products {
    .dropdown-display-value {
      height: 41px;
    }
  }
}

@media screen and (max-width: 576px) {
  .isMobile {
    display: block !important;
  }

  .isDesktop {
    display: none !important;
  }

  .nav-tabs {
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
