@import "../variables";

.btn {
  width: 100%;
  height: 46px;

  border-radius: 5px;

  font-size: 16px;
  font-weight: 600;

  &__outline {
    border: 1px solid $black;
    color: $black;
    max-width: 165px;

    svg {
      margin-right: 10px;
    }
  }

  &__blue {
    max-width: 165px;
    background: $base-blue;
    color: #ffffff !important;

    &:hover {
      background: #ffffff;
      border: 1px solid $base-blue;
      color: $base-blue !important;
    }
  }

  &__orange {
    max-width: 170px;
    height: 48px;
    border-radius: 8px;
    background: #FEE005;

    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
      background: #d9c004;
    }
  }

  &--mua {
    background: $base-blue;
    color: $white;
    max-width: 90px;
    width: 100%;
    height: 32px;
    margin: 0 !important;
    padding: 0.3rem 1rem;
    font-size: 14px;

    &:hover {
      border: 1px solid $base-blue;
      color: $base-blue;
    }
  }
}
