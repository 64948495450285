@import "../variables";

.header-top {
  height: 200px;
  width: 100%;
  padding-top: 67px;
  background: url("../../../../assets/params/images/background/bg-head-page.webp") no-repeat top;
  background-color: $base-blue;
  background-position: 50% 31%;
  background-size: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.title-child {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    margin-top: 50px;
    font-size: 24px;
  }

  @media screen and (max-width: 576px) {
    margin-top: 0;
  }

  span {
    display: block;
    font-size: calc((46 / 16) * 1rem);
    font-family: "Lexend";
    font-weight: 700;
    color: #fff;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin-top: 10px;
    }

    @media screen and (max-width: 576px) {
      font-size: 26px !important;
      line-height: 30px !important;
    }
  }
}

// -----------------------------
@media screen and (max-width: 992px) {
  .header-top {
    height: 115px;

    .title-child {
      margin-top: 0;
    }

    h1 {
      font-size: 30px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .header-top {
    height: 100px;
    padding-bottom: 0 !important;

    .title-child {
      span {
        margin-top: 0;
      }
    }

    h1 {
      font-size: 24px !important;
    }
  }


}
